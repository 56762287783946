<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="属性名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="属性代码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="数据类型">
        <el-select v-model="form.type">
          <el-option label="string" value="string"></el-option>
          <el-option label="text" value="text"></el-option>
          <el-option label="longtext" value="longtext"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="长度">
        <el-input v-model="form.length"></el-input>
      </el-form-item>
      <el-form-item label="小数位数">
        <el-input v-model="form.scale"></el-input>
      </el-form-item>
      
      <el-form-item label="输入控件">
        <el-select v-model="form.input_widget">
          <el-option label="文本框" value="1"></el-option>
          <el-option label="多行文本框" value="2"></el-option>
          <el-option label="下拉框" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},
    }
  },

  methods: {
    show(initForm, options) {
      this.form = _.assign({}, initForm, {})
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('meta_model_attribute.update', this.form.id, this.form);
        } else {
          await this.$api.execute('meta_model_attribute.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        this.$message.error(e.message);
      }
    },
  }
}
</script>