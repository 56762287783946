<template>
  <div>
    <el-card style="width: 300px; float: left; min-height: calc(100vh - 300px);">
      <el-tree node-key="node_key" :default-expanded-keys="expandedKeys" @node-collapse="handleNodeCollapse" @node-expand="handleNodeExpand" :expand-on-click-node="false" @node-click="handleNodeClick" :data="data">
        <template slot-scope="{data}">
          <span class="el-tree-node__label"><i v-if="data.icon" :class="data.icon" style="margin-right: 3px"></i>{{data.label}}</span>
        </template>
      </el-tree>
    </el-card>
    <div v-if="currentNode" style="margin-left: 320px;">
      <a-meta-model-package v-if="currentNode.type === 1 || currentNode.type === 2" :node="currentNode" @success="refresh" />
      <a-meta-model v-else-if="currentNode.type === 3" :node="currentNode" @success="refresh" />
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import AMetaModelPackage from './MetaModelPackage';
import AMetaModel from './MetaModel';

export default {
  components: { AMetaModelPackage, AMetaModel },
  data() {
    return {
      data: [],
      currentNode: null,
      expandedKeys: ['root'],
    }
  },
  created() {
    this.refresh();
  },
  methods: {
    async refresh() {
      const [{ list: meta_model_package}, { list: meta_model }] = await this.$api.executeArray(
        ['meta_model_package.list'],
        ['meta_model.list']
      );

      const listChildren = (list, parent_id, mapIteratee, mapIteratee2) => {
        const mapChildren = o => {
          const children = []
            .concat(listChildren(list, o.id, mapIteratee, mapIteratee2))
            .concat(_(meta_model).filter({ package_id: o.id }).map(mapIteratee2).value())
          return _.size(children) ? _.assign({}, o, { children }) : o;
        }
        return _(list).filter({ parent_id }).map(mapChildren).map(mapIteratee).value();
      }

      this.data = [
        { label: '技术元模型', type: 1, node_key: 'root', children: listChildren(meta_model_package, null,
          o => _.assign({ icon: 'el-icon-suitcase' }, o, { label: o.name, type: 2, node_key: `2-${o.id}` }),
          o => _.assign({ }, o, { label: o.name, type: 3, node_key: `3-${o.id}` })
        )}
      ]
    },
    handleNodeClick(e) {
      this.currentNode = e;
      console.log('handleNodeClick', e)
    },

    handleNodeExpand(e) {
      this.expandedKeys = _.unionBy(this.expandedKeys, [e.node_key]);
    },

    handleNodeCollapse(e) {
      const mapChildren = o => _.flatten([o.node_key, ..._.map(o.children,mapChildren)])
      this.expandedKeys = _.difference(this.expandedKeys, mapChildren(e));
    }
  }
}
</script>