<template>
  <div>
    <el-button style="margin-bottom: 15px;" @click="createRecord">新建</el-button>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="关系名称" width="180" />
      <el-table-column prop="code" label="关系代码" width="180" />
      <el-table-column prop="compose_model_name" label="元模型名" width="180" />
      <el-table-column prop="compose_model_code" label="元模型代码" width="180" />
      <!-- <el-table-column prop="prop5" label="多重性" width="180" /> -->
      <el-table-column prop="description" label="描述" width="180" />
      <!-- <el-table-column prop="prop6" label="是否内置" width="80" /> -->
      <el-table-column label="操作" width="100">
        <template slot-scope="{row}">
          <el-button @click="updateRecord(row)" type="text" size="small">编辑</el-button>
          <el-button @click="removeRecord(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>

    <edit-form ref="editForm" />
  </div>
</template>
<script>
// import _ from 'lodash';
import EditForm from './EditForm'
export default {
  components: { EditForm },
  props: {
    node: Object
  },
  data() {
    return {
      isShow: false,
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
    }
  },
  watch: {
    node() {
      this.isShow && this.refresh()
    }
  },
  methods: {
    show() {
      this.isShow = true;
      this.refresh();
    },
    hide() {
      this.isShow = false;
    },
    async refresh() {
      console.log('refresh')
      
      this.loading = true;
      const res = await this.$api.execute('meta_model_compose.list', { model_id: this.node.id });
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },

    createRecord() {
      this.$refs.editForm.show({ model_id: this.node.id }, { title: '新增组合', success: () => {
        this.refresh();
      }});
    },
    updateRecord(row) {
      this.$refs.editForm.show(row, { title: '编辑组合', success: () => {
        this.refresh();
      }});
    },

    async removeRecord(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta_model_compose.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },
  }
}
</script>