<template>
  <div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="关系名称" width="180" />
      <el-table-column prop="code" label="关系代码" width="180" />
      <el-table-column prop="model.name" label="元模型名" width="180" />
      <el-table-column prop="model.code" label="元模型代码" width="180" />
      <el-table-column prop="prop5" label="多重性" width="180" />
      <el-table-column prop="prop5" label="描述" width="180" />
      <el-table-column prop="prop6" label="是否内置" width="80" />
    </el-table>

    <el-pagination
      style="margin-top: 20px; text-align: right;"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
// import _ from 'lodash';
export default {
  props: {
    node: Object
  },
  data() {
    return {
      isShow: false,
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
    }
  },
  watch: {
    node() {
      this.isShow && this.refresh()
    }
  },
  methods: {
    show() {
      this.isShow = true;
      this.refresh();
    },
    hide() {
      this.isShow = false;
    },
    async refresh() {
      console.log('refresh')
      this.loading = true;
      const res = await this.$api.execute('meta_model_compose.list', { compose_model_id: this.node.id });
      this.tableData = res.list;
      this.total = res.count;
      this.loading = false;
    },
  }
}
</script>