<template>
  <el-card>
    <div style="margin-bottom: 15px;">
      <el-button @click="createMetaModelPackage">新建包</el-button>
      <el-button @click="createMetaModel">新建元模型</el-button>
      <el-button v-if="node && node.objectId" @click="updateMetaModelPackage(node)">编辑当前包</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" border height="calc(100vh - 400px)">
      <el-table-column prop="name" label="名称" min-width="180" />
      <el-table-column prop="code" label="代码" min-width="180" />
      <el-table-column prop="type" label="类型" width="100">
        <template slot-scope="{row}">
          {{{1: '包', 2: '元模型'}[row.type] || row.type}}
        </template>
      </el-table-column>
      <el-table-column prop="create_time" label="创建时间" width="140" />
      <el-table-column prop="update_time" label="更新时间" width="140" />
      <el-table-column label="操作" width="120">
        <template slot-scope="{row}">
          <el-button v-if="row.type === 1" @click="updateMetaModelPackage(row)" type="text" size="small">编辑</el-button>
          <el-button v-if="row.type === 1" @click="removeMetaModelPackage(row)" type="text" size="small">删除</el-button>
          <el-button v-if="row.type === 2" @click="updateMetaModel(row)" type="text" size="small">编辑</el-button>
          <el-button v-if="row.type === 2" @click="removeMetaModel(row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <a-meta-model-package-edit-form ref="metaModelPackageEditForm" />
    <a-meta-model ref="metaModelEditForm" />
  </el-card>
</template>
<script>
import _ from 'lodash';
import AMetaModelPackageEditForm from './MetaModelPackageEditForm';
import AMetaModel from './MetaModel';

export default {
  components: { AMetaModelPackageEditForm, AMetaModel },
  props: {
    node: Object
  },
  data() {
    return {
      tableData: [],
      currentPage: 1,
      pageSize: 20,
      total: 0,
      loading: false,
    }
  },
  watch: {
    node() {
      this.refresh()
    }
  },
  created() {
    this.refresh()
  }, 

  methods: {
    createMetaModelPackage() {
      this.$refs.metaModelPackageEditForm.show({ parent_id: this.node.id }, { title: '新增包', success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },
    updateMetaModelPackage(obj) {
      this.$refs.metaModelPackageEditForm.show(obj, { title: '编辑包', success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },

    async removeMetaModelPackage(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta_model_package.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    createMetaModel() {
      this.$refs.metaModelEditForm.show({ package_id: this.node.id }, { title: '新增元模型', success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },

    updateMetaModel(obj) {
      this.$refs.metaModelEditForm.show(obj, { title: '编辑元模型', success: () => {
        this.refresh();
        this.$emit('success')
      }});
    },

    async removeMetaModel(obj) {
      try {
        await this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        });

        await this.$api.execute('meta_model.remove', obj.id)

        this.refresh();
        this.$emit('success')
        this.$message({ type: 'success', message: '删除成功!' });
      } catch(e) {
        if(e !== 'cancel') { 
          this.$message.error(e.message);
        }
      }
    },

    async refresh() {
      if(this.node.id) {
        this.loading = true;
        const [{ list: meta_model }, { list: meta_model_package }] = await this.$api.executeArray(
          ['meta_model.list', { package_id: this.node.id }],
          ['meta_model_package.list', { parent_id: this.node.id }]
        );
        this.tableData = []
          .concat(_(meta_model_package).map(o => _.assign({}, o, { type: 1 })).value())
          .concat(_(meta_model).map(o => _.assign({}, o, { type: 2 })).value())
        this.loading = false;
      } else {
        this.loading = true;
        const { list: meta_model_package } = await this.$api.execute('meta_model_package.list', { parent_id: null });
        this.tableData = []
          .concat(_(meta_model_package).map(o => _.assign({}, o, { type: 1 })).value())
          
        this.loading = false;
      }
    }
  }
}
</script>