<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false" width="600px">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="代码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <el-select v-model="form.icon">
          <el-option v-for="item in iconOptions" :key="item" :label="item" :value="item">
            <i :class="item"></i> {{item}}
          </el-option>
        </el-select>
      </el-form-item>

      <div style="padding-left:100px;margin-bottom: 18px;">

        <el-checkbox v-model="form.mount_pos" :true-label="1" :false-label="0">
          是否挂载点
        </el-checkbox>
        <el-checkbox v-model="form.show_compose" :true-label="1" :false-label="0">
          显示组合
          <el-tooltip content="元数据树形展示时，是否显示组合" placement="top-start">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-checkbox>

        <el-checkbox :disabled="!form.show_compose" v-model="form.show_compose_group" :true-label="1" :false-label="0">
          组合分组
          <el-tooltip content="元数据树形展示时，是否以分组的形式显示组合" placement="top-start">
            <i class="el-icon-info"></i>
          </el-tooltip>
        </el-checkbox>
      </div>
      
      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
      
    </el-form>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
import icon from '@/icon';
console.log({ icon })

export default {
  data() {
    return {
      visible: false,
      form: {},
      options: {},
      defaultForm: { name: '', code: '', icon: '', mountPos: 0, showCompose: 0, showComposeGroup: 0 },
      iconOptions: icon,
    }
  },

  methods: {
    show(initForm, options = {}) {
      this.form = _.assign({}, this.defaultForm, initForm);
      console.log(this.form)
      this.options = options;
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('meta_model.update', this.form.id, _.pick(this.form, ['name', 'icon', 'mount_pos', 'show_compose', 'show_compose_group']));
        } else {
          await this.$api.execute('meta_model.create', _.pick(this.form, ['name', 'icon', 'mount_pos', 'show_compose', 'show_compose_group', 'code', 'package_id']));
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    },
  }
}
</script>