<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="代码">
        {{node.code}}
      </el-form-item>
      <el-form-item label="名称">
        {{node.name}}
      </el-form-item>
      <el-form-item label="图标">
        <i v-if="node.icon" :class="node.icon"></i>
      </el-form-item>
      <el-form-item label="是否挂载点">
        {{node.mount_pos ? '是' : '否'}}
      </el-form-item>
      <el-form-item label="显示组合">
        {{node.show_compose ? '是' : '否'}}
      </el-form-item>
      <el-form-item label="组合分组">
        {{node.show_compose_group ? '是' : '否'}}
      </el-form-item>
      <el-form-item label="创建时间">
        {{node.create_time}}
      </el-form-item>
      <el-form-item label="更新时间">
        {{node.update_time}}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: {
    node: Object
  },
  data() {
    return {
      form: {},
    }
  },

  methods: {
    handleCreate() {
      this.$refs.editForm.show();
    },
    edit({ row }) {
      this.$refs.editForm.show(row);
    },
  }
}
</script>