<template>
  <el-dialog :title="options.title" :visible.sync="visible" :close-on-click-modal="false">
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="confirmForm">确 定</el-button>
    </span>

    <el-form ref="form" :model="form" label-width="100px">
      
      <el-form-item label="关系名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="关系代码">
        <el-input v-model="form.code"></el-input>
      </el-form-item>
      <el-form-item label="元模型">
        <el-select v-model="form.compose_model_id">
          <el-option v-for="o in modelOptions" :key="o.id" :label="o.name" :value="o.id" />
        </el-select>
      </el-form-item>

      <!-- <el-form-item label="多重性">
        <el-radio-group v-model="form.resource">
          <el-radio label="*(零个或多个)"></el-radio>
          <el-radio label="0..1(零个或一个)"></el-radio>
          <el-radio label="1..*(一个或多个)"></el-radio>
        </el-radio-group>
      </el-form-item> -->

      <el-form-item label="描述">
        <el-input v-model="form.description" type="textarea" resize="none" :rows="3" />
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import _ from 'lodash';
export default {
  data() {
    return {
      visible: false,
      options: {},
      form: {},
      defaultForm: {},

      modelOptions: []
    }
  },
  created() {
    this.init();
  },

  methods: {
    async init() {
      this.modelOptions = (await this.$api.execute('meta_model.list')).list;
    },
    show(initForm, options) {
      this.form = _.assign({}, initForm, {});
      this.options = options;
      this.init();
      this.visible = true;
    },

    async confirmForm() {
      try {
        if(this.form.id) {
          await this.$api.execute('meta_model_compose.update', this.form.id, this.form);
        } else {
          await this.$api.execute('meta_model_compose.create', this.form);
        }
        this.options.success && this.options.success();
        this.visible = false;
      } catch(e) {
        console.log({ e })
      }
    },
  }
}
</script>